import { ErrorHandler, inject, provideAppInitializer } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JbdCoreHttpInterceptor } from '../../utils/http/http.interceptor';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProviders = (environment: any) => [
  {
    provide: 'environment',
    useValue: environment,
  },
  ...((environment.name !== 'local' && [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
      const initializerFn = ((() => () => {}) as any)(
        inject(Sentry.TraceService)
      );
      return initializerFn();
    }),
  ]) ||
    []),
  {
    provide: HTTP_INTERCEPTORS,
    useFactory: () => {
      return new JbdCoreHttpInterceptor(environment);
    },
    multi: true,
  },
];
