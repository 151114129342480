import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { JbdCoreImpersonateService } from '../../services/impersonate/impersonate.service';

@Injectable()
export class JbdCoreHttpInterceptor implements HttpInterceptor {
  constructor(
    @Inject('environment')
    private environment: { serverUrl: string; appDetails: { type: string } }
  ) {}

  private setServerUrl(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const url = this.environment.serverUrl;

    return request.clone({
      url: `${url}${request.url}`,
      params: request.params,
    });
  }

  private setRequestOptions(
    request: HttpRequest<unknown>
  ): HttpRequest<unknown> {
    return request.clone({
      withCredentials: true,
      url: request.url,
      params: request.params,
    });
  }

  private setContentTypeHeaders(
    request: HttpRequest<unknown>
  ): HttpRequest<unknown> {
    const headers = request.headers;

    if (!headers.has('Content-Type')) {
      headers
        .append('Accept', 'application/json')
        .append('Content-Type', 'application/json');
    }

    return request.clone({
      headers,
      url: request.url,
      params: request.params,
    });
  }

  private handleImpersonation(
    request: HttpRequest<unknown>
  ): HttpRequest<unknown> {
    if (!JbdCoreImpersonateService.getImpersonationDetails().isImpersonating) {
      return request;
    }

    return request.clone({
      url: request.url,
      params: request.params.append(
        '_switch_user',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        JbdCoreImpersonateService.getImpersonationDetails().impersonates!
      ),
    });
  }

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.responseType === 'text' || request.url.includes('assets')) {
      return next.handle(request);
    }

    request = this.setServerUrl(request);

    if (this.environment.appDetails.type !== 'public') {
      request = this.setRequestOptions(request);
    }

    request = this.setContentTypeHeaders(request);

    if (this.environment.appDetails.type !== 'public') {
      request = this.handleImpersonation(request);
    }

    return next.handle(request);
  }
}
